
import {
  defineComponent, onBeforeMount, reactive,
} from 'vue';
import { useAppBase } from '@/core/composables/AppBase';
import { ISetor } from '@/models/Entidades/ISetor';
import ComunicacaoApi from '@/core/components/UI/ComunicacaoApi.vue';
import ServicoSetor from '@/servicos/ServicoSetor';
import { IResposta } from '@/core/models/IResposta';

export default defineComponent({
  name: 'SetorCadastro',
  components: {
    ComunicacaoApi,
  },
  setup() {
    const {
      appBase, apresentarComunicacaoApi, apresentarRespostaRedirecionamento, obterCodigoRota,
    } = useAppBase();
    const servicoSetor = new ServicoSetor();

    const state = reactive({
      Setor: {} as ISetor,
    });

    async function obterSetor(codigo:number) {
      appBase.carregando = true;
      state.Setor = await servicoSetor.obter(codigo);
      appBase.carregando = false;
    }

    function limparDados() {
      state.Setor = {} as ISetor;
      state.Setor.codigo = 0;
      state.Setor.status = true;
    }

    onBeforeMount(async () => {
      limparDados();
      const codigoSetor = obterCodigoRota();
      if (codigoSetor > 0) {
        await obterSetor(codigoSetor);
      }
    });

    async function salvar() {
      appBase.resposta = {} as IResposta;
      apresentarComunicacaoApi('Aguarde por favor... Estamos salvando as informações do Setor.');
      if (state.Setor.codigo === 0) {
        appBase.resposta = await servicoSetor.incluir(state.Setor);
      } else {
        appBase.resposta = await servicoSetor.atualizar(state.Setor);
      }

      apresentarRespostaRedirecionamento('Setores');
    }
    return {
      appBase,
      state,
      salvar,
    };
  },
});
